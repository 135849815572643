@import 'partials/core';

.paypalProcessorContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    width: 100%;

    .paypalButton {
        z-index: 10;
        width: calc(100% - 10px);
        height: 55px;

        &:hover~.hallowPaypalButton {
          background: $grey2Hallow;
        }

        &.missingUserEmail {
            pointer-events: none;
        }
    }

    .hallowPaypalButton {
        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        z-index: 20;
        width: 100%;
        height: 60px;

        background: $grey1Hallow;
        border: none;
        border-radius: 10px;

        cursor: pointer;
        pointer-events: none;

        transition: 0.2s;

        &.hasFocus {
            outline: -webkit-focus-ring-color auto 1px;
            transition: 0s;
        }
    }
}