@import 'partials/core';

.consentManager {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;

    display: none;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    padding: 30px;

    background: $white;
    
    @media only screen and (min-width: $tablet) {
        bottom: 0;
        height: 100vh;
        padding: 0 100px;
    }

    header.consentHeader {
        display: flex;
        justify-content: flex-end;

        margin: 0 0 30px;
        
        @media only screen and (min-width: $tablet) {
            margin: 40px 0;
        }
    }
        
    .consentContainer {
        display: flex;
        flex-direction: column;
        
        @media only screen and (min-width: $tablet) {
            flex-direction: row;
        }
        
        .descriptionContainer {
            flex: 1;
            
            margin-bottom: 30px;
            padding: 0;
            
            @include Radial;
            font-size: 16px;
            line-height: 19px;
            
            @media only screen and (min-width: $tablet) {
                margin-bottom: 0;
                padding-right: 30px;    
            }
            
            .title {
                margin-bottom: 10px;
            }
            
            .policyLink {
                text-decoration: underline;
            }
        }
        
        .formContainer {
            flex: 1;
            padding: 0;
            
            @media only screen and (min-width: $tablet) {
                padding-left: 30px;
            }
            
            .submitButton {
                margin-top: 30px;
                margin-bottom: 5px;
                background: $purple;
                
                @media only screen and (min-width: $tablet) {
                    max-width: 200px;
                    margin-bottom: 0;
                }
            }
        }
    }
}