@import 'partials/core';

.cardDropdownContainer {
    .cardDropdownButton {
        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;

        width: 100%;
        height: 60px;
        margin-bottom: 10px;
        padding: 15px;

        @include NeueMontreal;
        font-size: 17px;
        line-height: 20px;
        letter-spacing: 0.03em;
        color: black;

        background: $grey1Hallow;
        border: none;
        border-radius: 10px;

        cursor: pointer;

        transition: 0.2s;

        @media only screen and (min-width: $tablet) {
            margin-bottom: 16px;
            font-size: 18px;
            line-height: 22px;
        }

        &:hover {
            background: $grey2Hallow;
        }

        .arrowIcon {
            display: none;
            position: absolute;
            left: 12px;

            &.cardDropdownOpen {
                display: block;
            }
        }

        .buttonTextContainer {
            display: flex;
            flex-direction: row;
            margin-top: 3px;

            .cardIcon {
                margin-right: 8px;

                @media only screen and (min-width: $tablet) {
                    margin-top: 2px;
                }
            }
        }
    }
}