/*
    breakpoints
*/
$tablet: 768px;

/*
    layout values
*/
$pageBoundary: 20px;
$pageBoundary24: 24px;
$pageBoundary30: 30px;
$pageBoundary40: 40px;

/*
    onboarding
*/
$onboardingEnterTransitionLength: 500ms;
$onboardingExitTransitionLength: calc($onboardingEnterTransitionLength / 2);
$onboardingBackgroundColorTransitionLength: 500ms;

$onboardingHeader: 50px;
$onboardingHeaderTablet: 60px;

/*
    mixins
*/
// screen readers 'see' invisible components (i.e. inputs)
@mixin srOnly() {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    margin: -1px !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}