@import 'partials/core';

.separator {
    background-color: #EDEAEF;
}

.separator[data-orientation='horizontal'] {
    height: 2px;
    min-height: 2px;
    width: 100%;
}

.separator[data-orientation='vertical'] {
    height: 100%;
    min-width: 2px;
    width: 2px;
}