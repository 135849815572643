.flex {
    align-items: center;
    display: flex;
    gap: 12px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.input {
    border: 1px solid #DCD8DF;
    border-radius: 12px;
    font-size: 16px;
    height: 64px;
    line-height: 20px;
    padding: 12px 16px;
    width: 100%;
}

.input::placeholder {
    color: #59525F;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type=number] {
  -moz-appearance: textfield;
}
