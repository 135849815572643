@import 'partials/core';

// When editing the below classes, test updates on each page type and resolution
// 1) above-the-fold-only desktop, 2) above-the-fold-only mobile,
// 3) scrollable desktop, 4) scrollable mobile
// Default classes are added via Layout.tsx, pages edit classes via pageProvider

.accessApp {
    display: flex;
    flex-direction: column;

    &.pageCenter {
        justify-content: center;

        min-height: 100vh;
    }
    
    &.pageFullBackground {
        min-height: calc(100vh + 230px); // 230px for mobile footer

        @media only screen and (min-width: $tablet) {
            min-height: unset
        }

        > div:first-of-type, main:first-of-type {
            width: 100%;
            min-height: calc(100vh + 230px); // 230px for mobile footer

            background-position: center;
            background-size: cover;

            @media only screen and (min-width: $tablet) {
                min-height: 100vh;
            }
        }

        &.footerIsRelative {
            > div:first-of-type, main:first-of-type {
                padding-bottom: 230px;

                @media only screen and (min-width: $tablet) {
                    padding-bottom: 64px;
                }
            }
        }
    }

    .carouselFullBackground { // manually apply this class to your carousel (as container class)
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: calc(100% + 117px); // 117px for footer

        @media only screen and (min-width: $tablet) {
            height: 100%;
        }
    
        .carouselItem {   
            height: calc(100vh + 117px); // 117px for footer

            @media only screen and (min-width: $tablet) {
                height: 100vh;
            }
        }
    }

    footer {
        &.footerFullBackground {
            position: absolute;
            bottom: -230px;

            @media only screen and (min-width: $tablet) {
                position: fixed;
                bottom: 0;
                color: $grey3Hallow;
                background: $black_40;

                a { color: $grey3Hallow }

                @media only screen and (min-height: $tablet) {
                    color: $grey;
                    background: none;
    
                    a { color: $grey }
                }
            }

            &.footerPositionRelative {
                height: 0px;
                bottom: 160px;
                padding: 0 20px;

                @media only screen and (min-width: $tablet) {
                    bottom: 42px;
                    padding: 0 48px 0;
                }
            }
        }

        &.footerFullCarousel { // works with the above .carouselFullBackground class, which must be manually applied to carousel container
            padding: 0 20px 15px;

            @media only screen and (min-width: $tablet) {
                padding: 0 48px 20px;
            }
        }

        // for mobile resolutions when there are two fixed buttons present
        &.footerTwoButtons {
            padding: 0 20px calc(165px + var(--add-mobile-footer-margin));

            @media only screen and (min-width: $tablet) {
                padding: 0 48px 20px;
            }
        }

        // to re-position footer when page content exceeds viewport's height
        &.footerPositionRelative {
            position: relative;
        }
    }
}