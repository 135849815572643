@import 'partials/core';

.stripeCardContainer {
    width: 100%;
    max-width: 560px;

    font-size: 18px;

    border-radius: 5px;

    input {
        margin-bottom: 10px;

        @media only screen and (min-width: $tablet) {
            margin-bottom: 16px;
        }
    }

    $inputPadding: 15px;
    > div {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 60px;
        margin-bottom: 15px;
        padding: $inputPadding;

        font-size: 18px;

        background: white;
        border: solid 1px $grey3Hallow;
        border-radius: 5px;

        @media only screen and (min-width: $tablet) {
            margin-bottom: 24px;
        }

        > div {
            width: calc(100vw - $inputPadding - $pageBoundary);
        }
    }

    button {
        pointer-events: all;

        &:first-of-type {
            margin-bottom: 16px;

            @media only screen and (min-width: $tablet) {
                margin-bottom: 0;
            }
        }
    }
}