@import 'partials/core';

.table {
    border-spacing: 0;
    width: 100%;
}

.tbody {
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.td {
    @include NeueMontreal;
    font-size: 1.125rem;
    line-height: 1.25rem;
}

.tdBold {
    @include RadialBold;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.25rem;
}

.tdCapitalize {
    text-transform: capitalize;
}

.tr {
    display: flex;
    justify-content: space-between;
}