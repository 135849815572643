@import 'partials/core';

// TODO
// .displayDesktop {
//     display: none;
// }

.marqueeContainer {
    margin: 12px 0;

    @media only screen and (min-width: $tablet) {
        margin: 24px 0;
    }
}

.image {
    border-radius: 0.75rem;
    min-width: 7.5rem;
    width: 20vw;
}

.images {
    margin: 0 6px;
    position: relative;

    @media only screen and (min-width: $tablet) {
        margin: 0 12px;
    }
}

.span {
    @include Radial;
    align-items: center;
    bottom: 5px;
    color: #FFFFFF;
    display: flex;
    font-size: 0.875rem;
    left: 0;
    padding: 0.3125rem 1rem;
    position: absolute;

    @include NeueMontreal;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    word-wrap: break-word;
    white-space: pre-wrap;
    text-shadow: #111 1px 0 10px;

    pointer-events: none;

    @media only screen and (min-width: $tablet) {
        bottom: 12px;
        min-height: unset;

        font-size: 1.1rem;
        line-height: 22px;
    }
}

.spanDesktop {
    display: none;
}

@media only screen and (min-width: 768px) {
    // TODO
    // .displayDesktop {
    //     display: block;
    // }

    .span {
        font-size: 1.125rem;
        padding: 0.3125rem 1.25rem;
    }

    .spanDesktop {
        display: flex;
    }

    .spanMobile {
        display: none;
    }
}