@import 'partials/core';

.a {
    color: #1A85E8;
    font-weight: 600;
    line-height: 1.25rem;
}

.h1 {
    @include RadialBold;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    text-transform: capitalize;
}

.h2 {
    @include RadialBold;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    text-transform: capitalize;
}

.p {
    @include NeueMontreal;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.25rem;
}

.section {
    margin-bottom: 8rem;
}

.span {
    @include NeueMontreal;
    color: #7A757E;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
}