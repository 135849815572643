@import 'partials/core';

.contentOptionContainer {
    aspect-ratio: 8 / 9;
    margin: 35px 0 20px;
    position: relative;
    width: 100%;
}

.optionBase {
    aspect-ratio: 8 / 9;
    background-color: rgba(211, 207, 216, 1);
    border: solid 2px $white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    opacity: 1;
    overflow: hidden;
    position: absolute;
    transform: translateY(0) scale(1);
    transition: all .3s, background-color 0s;
    width: 100%;
    z-index: 3;
}

.hidden {
    opacity: 0;
    transform: translateY(-50px) scale(.8);
    z-index: 1;
}

.optionStateThird {
    background-color: rgba(231, 228, 233, 1);
    transform: translateY(-50px) scale(.8);
    z-index: 1;
}

.optionStateSecond {
    transform: translateY(-26px) scale(.9);
    z-index: 2;
}

.optionStateYes {
    transform: translateX(100vw);
}

.optionStateNo {
    transform: translateX(-100vw) translateY(175vh) rotate(-90deg);
    transform-origin: center;
    transition: .8s;
}

.optionImage {
    min-height: 88%;
    min-width: 100%;
    object-fit: cover;
}

.gradient {
    bottom: 0%;
    height: 35%;
    position: absolute;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0px 24px;
}

.cardTitle {
    @include RadialBold;
    color: $white;
    font-size: 24px;
    font-style: normal;
    line-height: 32px;
    text-align: center;
    width: 100%;
}

.cardTags {
    display: block;
    color: $white;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 2px 0px 24px 0px;
    text-align: center;
    width: 100%;
}

.buttonContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 20px 0;
}