@import 'partials/core';

.closeContainer {
    align-items: center;
    display: flex;
    height: 2rem;
    justify-content: flex-end;
}

.close {
    background: transparent;
    border: none;
    cursor: pointer;
}

.content {
    animation: all 100ms ease-in;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    padding: 1.25rem;
    top: 0;
    width: 100vw;
    z-index: 10001;
}

.content:focus {
    outline: none;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.3);
    inset: 0;
    position: fixed;
    z-index: 1000;
}

@media only screen and (min-width: $tablet) {
    .content {
        border-radius: 1.5rem;
        left: 50%;
        max-height: 21.875rem;
        max-width: 31.25rem;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}