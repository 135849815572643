@import 'partials/core';

.walletPayButton {
    width: 100%;
    height: 60px;

    background: $grey1Hallow;
    border: none;
    border-radius: 10px;

    cursor: pointer;

    transition: 0.2s;
    
    &:hover {
        background: $grey2Hallow;
    }
}

.walletPayButton:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
