@import 'partials/core';

.consentPopup {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 6;
    width: 100%;
    max-width: 700px;
    padding: 30px;
    padding-right: 20px;

    display: none;
    flex-direction: column;

    background: $white;
    box-shadow: 0px 1px 4px 3px $purple_50;

    @media only screen and (min-width: $tablet) {
        bottom: 30px;
        padding-top: 20px;
        border-radius: 12px;
    }

    .consentHeader {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .consentContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media only screen and (min-width: $tablet) {
            flex-direction: row;
            padding: 25px;
        }

        .descriptionContainer {
            flex: 1;
            margin: 0 0 20px 0;

            @media only screen and (min-width: $tablet) {
                margin: 0 0 0 10px;
            }

            h4 {
                margin: 0 0 20px 0;

                @include RadialBold;
                font-size: 24px;
                line-height: 19px;
            }

            .policyLink {
                text-decoration: underline;
            }
        }

        .buttonsContainer {
            display: flex;
            align-items: flex-end;
            flex: 1;
            width: 100%;

            @media only screen and (min-width: $tablet) {
                flex-direction: column;
                margin-right: 25px;
            }

            button {
                max-height: 50px;

                @media only screen and (min-width: $tablet) {
                    max-width: 150px;
                }

                &.buttonManage {
                    margin: 0 10px 0 0;
                    color: $black;
                    background: $grey3Hallow;
                    
                    @media only screen and (min-width: $tablet) {
                        margin: 0 0 12px 0;
                    }
                }
                
                &.buttonAcceptAll {
                    color: $white;
                    background: $purple;
                }
            }
        }
    }
}