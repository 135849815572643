@import 'partials/core';

.arrowIcon {
    &.up {
        transform: rotate(90deg)
    }
    
    &.right {
        transform: rotate(180deg)
    }
    
    &.down {
        transform: rotate(270deg)
    }
    
    &.left {
        transform: rotate(0deg)
    }
}