@import 'partials/core';

.consentCheckboxContainer {
    padding: 0 5px;
    border-top: solid 1px $black_10;
    
    &:last-of-type {
        border-bottom: solid 1px $black_10;
    }

    .consentCheckboxBar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
            
        font-size: 16px;
        line-height: 19px;

        .consentCheckbox {
            display: flex;
            align-items: center;

            padding: 15px 30px 15px 0;

            cursor: pointer;

            .consentCheckboxInput {
                @include srOnly;
            
                &:checked {
                    & ~ label {
                        color: $purple;
                    }
            
                    & ~ svg {
                        .box {
                            fill: $purple
                        }
            
                        .checkmark {
                            stroke: $white;
                        }
                    }
            
                    &:disabled {
                        & ~ label {
                            color: rgba($purple, 0.4);
                        }

                        & ~ svg .box {
                            fill: rgba($purple, 0.3);
                        }
                    }
                }
            }

            .checkboxIcon {
                margin-right: 15px;

                .box {
                    fill: $grey1Hallow;
                }
        
                .checkmark {
                    stroke: $grey1Hallow;
                }
            }

            .consentCheckoutLabel {
                color: $grey4Hallow;
                cursor: pointer;
            }
        }
    }

    .expander {
        display: flex;
        justify-content: flex-end;
        flex: 1;

        padding: 20px;

        cursor: pointer;

        .angleIcon {
            &.open {
                transform: rotate(180deg);
            }
        }
    }

    .checkboxDescriptionContainer {
        margin: 0 45px 15px;
    }
}