@import 'partials/core';

.inputLabel {
    position: relative;
    display: block;

    .inputPlaceholder {
        position: absolute;
        top: 22px;
        left: 20px;
        width: max-content;
        
        @include NeueMontreal;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.03em;
        color: $grey4Hallow;
        
        pointer-events: none;
        
        transition: all 250ms;
        
        &:active {
            top: 10px;
            font-size: 14px;
            line-height: 18px;    
        }
    }
    
    .inputPlaceholderPopulated {
        top: 10px;
        font-size: 14px;
        line-height: 18px;
    }
    
    .input {
        width: 100%;
        padding: 28px 20px 10px;

        font-size: 18px;
        line-height: 22px;
        color: $black;
        
        background: $white;
        border: 1px solid $grey3Hallow;
        border-radius: 6px;
        
        &:focus-visible {
            outline: none;
            border: 1px solid $purple;
        }
        
        &:disabled {
            color: $grey4Hallow;
            cursor: not-allowed;
        }
    }
}