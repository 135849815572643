@use 'sass:math';

@font-face {
    font-family: 'Freight Text Pro';
    src: url('../fonts/FreightTextProBook.woff2') format('woff2'),
        url('../fonts/FreightTextProBook.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Montreal';
    src: url('../fonts/NeueMontreal-Regular.woff2') format('woff2'),
        url('../fonts/NeueMontreal-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'Neue Montreal Tabular';
//     src: url('../fonts/NeueMontreal-TabularNums.woff2') format('woff2'),
//         url('../fonts/NeueMontreal-TabularNums.woff') format('woff');
//     font-weight: 400;
//     font-style: normal;
//     font-variant: tabular-nums;
//     font-feature-settings: 'tnum';
//     font-display: swap;
// }

@font-face {
    font-family: 'Radial';
    src: url('../fonts/Radial-Regular.woff2') format('woff2'),
        url('../fonts/Radial-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Radial Semi Bold';
    src: url('../fonts/Radial-Semibold.woff2') format('woff2'),
        url('../fonts/Radial-Semibold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Radial Bold';
    src: url('../fonts/Radial-Bold.woff2') format('woff2'),
        url('../fonts/Radial-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'Radial Italic';
//     src: url('../fonts/Radial-Italic.woff2') format('woff2'),
//         url('../fonts/Radial-Italic.woff') format('woff');
//     font-weight: 400;
//     font-style: italic;
//     font-display: swap;
// }

@mixin FreightTextPro() {
    font-family: Freight Text Pro, serif;
    font-weight: 400;
}

@mixin NeueMontreal() {
    font-family: Neue Montreal, sans-serif;
}

// @mixin NeueMontrealTabular() {
//     font-family: var(--font-override), Neue Montreal Tabular, monospace;
//     letter-spacing: calc(0.03em + var(--add-letter-spacing));
//     word-spacing: var(--add-word-spacing);
//     font-variant: tabular-nums;
//     font-feature-settings: 'tnum';
// }

@mixin Radial() {
    font-family: Radial, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 400;
}

@mixin RadialSemiBold() {
    font-family: Radial Semi Bold, Radial, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 500;
}

@mixin RadialBold() {
    font-family: Radial Bold, Radial Semi Bold, Radial, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 700;
}

// @mixin RadialItalic() {
//     font-family: var(--font-override), Radial Italic, Radial, Helvetica Neue, Helvetica, Arial, sans-serif;
//     letter-spacing: var(--add-letter-spacing);
//     word-spacing: var(--add-word-spacing);
// }