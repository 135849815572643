/*
    APP COLORS
*/

// Black Alpha Colors
$black_10: #0000001A;
$black_20: #00000033;
$black_30: #0000004D;
$black_40: #00000066;
$black_70: #000000B3;
$black_80: #000000CC;

// Grey Alpha Colors
$grey_10: #2C2C2C1A;
$light_grey_60: #B9B4BB99;

// White Alpha Colors
$white_10: #FFFFFF1A;
$white_30: #FFFFFF4D;
$white_50: #FFFFFF80;
$white_60: #FFFFFF99;
$white_80: #FFFFFFCC;
$white_90: #FFFFFFE6;

// Collection Colors
$teal: #00CAE8;
$salmon: #FF5B78;
$blue: #3C6BFB;

// Parish Colors
$parish_purple: #260150;

// Mood Colors
$mood_color_good: #FFF8BC;
$mood_color_neutral: #D7D4E8;
$mood_color_depressed: #A7B0FF;
$mood_color_bad: #FFB800;

// Group Colors
$group_colors_intention_background: #3A1855;
$group_colors_qr_code: #4EB092;

// Other Colors
$aqua: #5AE0AF;
$aqua_40: #5AE0AFB3;
$pillGreen: #B7E5CF;
$pillBlue: #CFE6F9;
$pillRed: #F1E7F9;
$palePink: #FFE6F2;
$cream: #FFC9B6;
$signupPostPrayerColor: #E5D0FF;
$triviaBackgroundColor: #380268;
$triviaButtonColor: #935AC3;
$triviaWrongAnswerColor: #FF4762;
$whatsapp_color: #25D366;
$whatsapp_color_disabled: #8BDEAB;
$winbackAffirmationColor: #9857CF;
$onboardingNotificationPrimerBackgroundColor: #83B1CF;

// Color (universal)
$white: #FFFFFF;
$black: #030303;
$blackish: #3a3a3a;
$transparent: #00FFFFFF;
$green: #25C389;
$brightGreen: #00D689;
$grey: #B9B4BB;
$lightGrey: #F1F0F2;
$darkGrey: #767676;
$red: #DD223D;
$linkBlue: #3366BB;

$palePurple: #F1E7F9;
$palerPurple_66: #490089A8;
$lightPurple: #9956D3;
$lighterPurple: #9131E6;
$purple: #6D0EC1;
$purple_50: #6D0EC17F;
$darkPurple: #490089;
$darkerPurple: #3C0071;
$darkestPurple: #300D49;
$pink: #CF1A96;
$darkerPink: #B51F86;

$subscriptionOrange: #f2755f;

// LIGHT COLOR THEME - see android /values/colors.xml
$groupListColor: #56C3A2;
$groupListColorVariant: #154B62;

$grey1Hallow: #F6F5F8;
$grey2Hallow: #EDEAEF;
$grey3Hallow: #D9D5DD;
$grey4Hallow: #7A757E;