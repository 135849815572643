@import 'partials/core';

.container {
    color: $black;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 2rem auto auto auto;
    max-width: 100vw;
    padding: 0 20px;

    @media only screen and (min-width: $tablet) {
        margin-bottom: 10rem;
        max-width: 36rem;
    }
}

.checkout {
    gap: 2rem;
    padding-top: 4rem;
}

.fixed {
    position: sticky;
    top: 0;
    z-index: 999;
}

.marginTop {
    margin-top: 1rem;
}

.stack {
    display: flex;
    flex-direction: column;
}

.strikethrough {
    text-decoration: line-through;
}

.textStack {
    text-align: center;
}

.textStackXs {
    gap: 0.5rem;
}

.textStackSm {
    gap: 1rem;
}

.textStackMd {
    gap: 1.25rem;
}

.textStackLg {
    gap: 1.5rem;
}