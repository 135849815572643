@import 'partials/core';

.button {
    align-items: center;
    cursor: pointer;
    border: none;
    border-radius: 1.875rem;
    box-sizing: border-box;
    display: flex;
    font-size: 1.125rem;
    height: 3.75rem;
    justify-content: center;
    padding: 1.125rem;
    letter-spacing: 0em;
    line-height: 1.375rem;
    text-align: center;
    transition: all 0.25s ease-in-out;
    width: 100%;
}

.primary {
    background-color: #000000;
    font-family: Radial Semi Bold, Radial, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 500;
    color: #FFFFFF;
}

.primary:disabled {
    background-color: #7A757E;
    color: #D9D5DD;
}

.primary:active {
    box-shadow: 0 0.1875rem 0.625rem #7A757E;
    outline: none;
}

.primary:focus {
    @media screen and (min-width: $tablet) {
        box-shadow: 0 0.1875rem 0.625rem #7A757E;
        outline: none;
    }
}

.primary:hover {
    @media screen and (min-width: $tablet) {
        background-color: #000000CC;
        text-decoration: none;
    }
}

.secondary {
    background-color: #FFFFFF;
    border: 1px solid #EDEAEF;
    color: #000000;
    font-family: Radial, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.secondary:hover {
    @media screen and (min-width: $tablet) {
        background-color: #F6F5F8;
    }
}