@import 'partials/core';

.pageCheckout {
    display: flex;
    justify-content: center;
    min-height: 100vh;

    @media only screen and (min-width: $tablet) {
        align-items: center;
    }

    .contentContainer { 
        width: 560px;
        max-width: calc(100vw - #{ $pageBoundary * 2 });
        margin: $pageBoundary auto;

        @media only screen and (min-width: $tablet) {
            margin-bottom: 65px;
        }

        .pageTitle {
            margin: 0 0 20px 0;

            @include RadialBold;
            font-size: 32px;
            line-height: 38px;

            @media only screen and (min-width: $tablet) {
                margin-bottom: 24px;
            }
        }

        .orderDetailsContainer {
            position: relative;
            margin-bottom: 10px;
            padding: 20px;

            text-align: center;
            color: $purple;

            background: $palePurple;
            border-radius: 12px;

            @media only screen and (min-width: $tablet) {
                margin-bottom: 20px;
                padding: 30px;
            }

            &.activePromotion {
                margin-top: 35px;
                padding: 30px 30px 28px;;
            }

            .promotionalOfferBanner {
                display: none;

                position: absolute;
                top: -16px;
                left: 50%;
                transform: translateX(-50%);

                margin: 0 auto;
                padding: 9px 10px 7px;

                @include NeueMontreal;
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
                text-transform: uppercase;
                color: $white;

                background: $purple;
                border-radius: 5px;

                @media only screen and (min-width: $tablet) {
                    font-size: 14px;
                    line-height: 17px;
                }

                &.activePromotion {
                    display: block;
                }
            }

            .orderDetailPrimary {
                margin: 0 0 10px;

                @include RadialBold;
                font-size: 24px;
                line-height: 29px;
                letter-spacing: -0.02em;

                @media only screen and (min-width: $tablet) {
                    font-size: 28px;
                    line-height: 34px;
                }

                &.capitalize {
                    text-transform: capitalize;
                }
            }

            .orderDetailSecondary {
                @include NeueMontreal;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0.03em;

                @media only screen and (min-width: $tablet) {
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }

        .lineBreak {
            display: block;

            width: 100%;
            height: 1px;
            margin-bottom: 20px;

            background: $grey3Hallow;

            @media only screen and (min-width: $tablet) {
                margin-bottom: 24px;
            }

            &.end {
                margin-top: 10px;

                @media only screen and (min-width: $tablet) {
                        margin-top: 8px;
                }
            }
        }

        .paymentProcessorsContainer {
            .paymentProcessorButton {
                margin-bottom: 10px;

                @media only screen and (min-width: $tablet) {
                    margin-bottom: 16px;
                }
            }

            .choosePaymentMethod {
                margin-bottom: 20px;

                @include NeueMontreal;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0.03em;
                color: $grey4Hallow;

                @media only screen and (min-width: $tablet) {
                    margin-bottom: 24px;
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }

        .fixedRedeemButtonContainer {
            position: fixed;
            top: 0;
            right: $pageBoundary;
            bottom: 0;
            left: $pageBoundary;
            z-index: 2;

            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            height: calc(var(--vh, 1vh) * 100);
            padding-bottom: 10px;

            pointer-events: none;
            outline: none;

            transition: all 0.4s;

            @media only screen and (min-width: $tablet) {
                position: relative;
                right: 0;
                left: 0;

                height: unset;
            }

            button {
                pointer-events: all;

                &:first-of-type {
                    margin-bottom: 16px;
                }
            }
        }
    }
}