@import 'partials/core';
* {
    -webkit-tap-highlight-color: transparent;
}

:root {
    --vh: 1vh;
}

html,
body,
#__next {
    height: 100vh;
}

html,
body {
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    @include Radial();

    // prevents pixel img tags from GTM ruining our 100vh layout
    img {
        position: absolute;
        height: 0;
    }

    // reset properties for all non-pixel img tags
    div img {
        position: relative;
        height: auto;
    }
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}

h1,
h2,
p,
ul {
    margin: 0;
}

ul {
    padding: 0;
    list-style: none;
}

.hallowButton {
    position: relative;
    width: 100%;
    height: 60px;
    padding: 18px;

    display: flex;
    justify-content: center;
    align-items: center;

    @include RadialSemiBold();
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: $white;

    background: $black;
    border: none;
    border-radius: 30px;

    transition: all 0.25s ease-in-out;

    pointer-events: all;
    cursor: pointer;

    &:hover {
        text-decoration: none;
        background: $black_80;
    }

    &:focus {
        outline: none;
        box-shadow: 0 3px 10px $black_70;
    }

    &:disabled {
        color: $grey3Hallow;
        background: $grey4Hallow;
    }

    &.loading {
        &:before {
            content: '';
            display: block;
            
            position: absolute;
            top: 30px;
            right: 20px;

            width: 0;
            height: 0;

            border-width: 2px;
            border-style: solid;
            border-color: $white_80 $black $white_80 $white_80;
            border-radius: 50%;

            animation: rotate360 .5s infinite linear, exist .1s forwards ease;
        }

        @keyframes rotate360 {
            100% {
                transform: rotate(360deg)
            }
        }

        @keyframes exist {
            100% {
                width: 12px;
                height: 12px;
                margin: -8px 5px 0 0;
            }
        }
    }

    &.alertButton {
        min-width: 200px;
    }
}