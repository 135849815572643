.label {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    text-align: left;
    color: #000000;
}

.radioItem {
    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 1rem;
    width: 100%;
    color: #000000;
}

.radioIndicatorContainer {
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #D9D5DD;
    border-radius: 999px;
    display: flex;
    height: 1.625rem;
    justify-content: center;
    width: 1.625rem;
}

.radioItem[aria-checked=true] > .radioIndicatorContainer {
    border: 2px solid #6D0EC1;
}

.span {
    text-transform: capitalize;
}