@use 'components/paymentDropdown.module.scss';
@import 'partials/core';

.cardDropdownContainer {
    .cardDropdownButton {
        padding: 0;
    }

    .errorMessage {
        margin-top: 5px;
        color: red;
        padding: 0 5px;
        font-size: 14px;
    }
}

.cardDropdownButton:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
