@import 'partials/core';

.text {
    @include Radial();
    color: $grey4Hallow;
    background: none;

    &:hover, &:focus {
        text-decoration: underline;
        background: none;
        box-shadow: none;
    }

    &:disabled, &.loading {
        width: unset;
        margin: 0 auto;
        padding: 0 60px;
        
        background: none;
    }
}

.apple {
    color: $black;
    background: $grey1Hallow;

    .buttonIcon {
        margin-right: -8px;
        padding-top: 3px;
    }

    &:hover {
        background: $grey2Hallow;
    }

    &:disabled:hover {
        background: $grey4Hallow;
    }
}

.google {
    color: $black;
    background: $grey1Hallow;

    .buttonIcon {
        margin-right: -3px;
    }
    
    &:hover {
        background: $grey2Hallow;
    }

    &:disabled:hover {
        background: $grey4Hallow;
    }
}

.whatsApp {
    background: $whatsapp_color;
    color: $white;

    .buttonIcon {
        margin-right: 8px;
    }

    &:disabled, &.loading {
        background: $whatsapp_color_disabled;
        color: $white;
    }
}

.whatsapp {
    background: $whatsapp_color;
    color: $white;

    .buttonIcon {
        margin-right: 8px;
    }

    &:disabled, &.loading {
        background: $whatsapp_color_disabled;
        color: $white;
    }
}