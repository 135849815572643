@import 'partials/core';

footer.footer {
    z-index: 0;
    width: 100vw;
    padding: 0 $pageBoundary 105px;

    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    @include NeueMontreal;
    font-size: 12px;
    line-height: 14px;
    color: $grey4Hallow;

    transition: 0.2s;
    
    @media only screen and (min-width: $tablet) {
        flex-direction: row;
        justify-content: space-between;

        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 4;
        width: unset;
        height: 64px;
        padding: $pageBoundary 48px;
        
        background: $white_80;
        backdrop-filter: blur(10px);
        
        @media only screen and (min-height: $tablet) {
            padding-top: 0;
            
            color: $grey;
            
            background: none;
            backdrop-filter: none;
        }
    }

    a {
        display: inline-block;
        color: $grey4Hallow;
        background: $white;

        transition: 0.2s;

        @media only screen and (min-width: $tablet) {
            background: none;
            
            &:hover {
                text-decoration: underline;
            }
        
            @media only screen and (min-height: $tablet) {
                color: $grey;
            }
        }
    }

    .footerName {
        margin: 12px 0 0;

        @media only screen and (min-width: $tablet) {
            margin: 0;
        }
    }

    .footerButtons {
        width: calc(100%);
        max-width: 100vw;

        @media only screen and (min-width: $tablet) {
            width: unset;
        }

        .footerButton {
            width: calc(50% - 4px);
            height: 30px;
            margin: 0 4px 8px;

            text-align: center;
            line-height: 30px;

            border: 1px solid $grey3Hallow;
            border-radius: 15px;
        
            &:nth-child(odd) {
                margin-left: 0;
            }
            
            &:nth-child(even) {
                margin-right: 0;
            }

            @media only screen and (min-width: $tablet) {
                width: unset;
                margin: 0 0 0 48px;

                border: none;
        
                &:nth-child(odd) {
                    margin-left: 48px;
                }
            }
        }
    }
}